import React, { createRef, useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'

const contentful = require('contentful')
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polygon,
  OverlayView,
  InfoWindow,
  Autocomplete,
} from '@react-google-maps/api'
import { Container } from 'reactstrap'
import { faShopify } from '@fortawesome/free-brands-svg-icons'
import { result } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faGlobe,
  faMapMarked,
  faMapMarkedAlt,
  faMapPin,
  faMousePointer,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { faMap } from '@fortawesome/free-regular-svg-icons'
const DealershipsComponent = () => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  // Autosuggest will pass through all these props to the input.

  const [activeResult, setActiveResult] = useState(null)

  const [dealerships, setDealerships] = useState([])
  const [results, setResults] = useState([])

  const [autocomplete, setAutocomplete] = useState(null)
  const [mapCenter, setMapCenter] = useState({
    lat: 53.5151979,
    lng: -2.35003194,
  })
  const [tempCenter, setTempCenter] = useState({
    lat: 53.5151979,
    lng: -2.35003194,
  })
  const [searchCenter, setSearchCenter] = useState({
    lat: 53.5151979,
    lng: -2.35003194,
  })
  const [hasSearched, setHasSearched] = useState(false)
  const [zoom, setZoom] = useState(8)
  const [searchDistance, setSearchDistance] = useState(1000)
  const [searchType, setSearchType] = useState('HGV')
  const [view, setView] = useState('MAP')
  // to change to live access
  const client = contentful.createClient({
    host: 'preview.contentful.com',
    space: 'tlpgbvy1k6h2',
    accessToken: 'Zr3l46OYsH7hKfpqJBnbR-G87wRn7b0YrLEb5bOfak0',
  })

  const [markerRefs, setMarkerRefs] = useState([])

  const locationChange = () => {
    if (autocomplete === null) {
      return
    }

    const place = autocomplete.getPlace()

    if (!place || !place.geometry || !place.geometry.location) {
      return
    }
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()

    setSearchCenter({
      lat,
      lng,
    })
  }

  useEffect(() => {
    const getContent = async () => {
      const result = await client.getEntries({
        content_type: 'dealership',
        order: 'sys.createdAt',
        include: 5,
        limit: 1000,
      })

      let ships = result.items.map(r => r.fields)
      ships = ships.filter(f => f.toBeDisplayedExternally)
      let j = 0
      while (j < ships.length) {
        const ship = ships[j]
        ship.id = j
        ship.visible = false
        ship.label = '1'
        j++
      }

      setDealerships(ships)

      setMarkerRefs(markerRefs =>
        Array(ships.length)
          .fill()
          .map((_, i) => markerRefs[i] || createRef())
      )
    }

    getContent()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setZoom(11)
      setMapCenter(tempCenter)
    }, 1000)
  }, [tempCenter])

  const search = () => {
    setValue('')

    const prefiltered = JSON.parse(JSON.stringify(dealerships))

    let ships = []

    if (searchType === 'HGV') {
      ships = prefiltered.filter(f => f.hgv)
    }

    if (searchType === 'MC') {
      ships = prefiltered.filter(f => f.hgvMotorhome && f.lgvMotorhome)
    }

    const haversineDistance = ([lat1, lon1], [lat2, lon2], isMiles = true) => {
      const toRadian = angle => (Math.PI / 180) * angle
      const distance = (a, b) => (Math.PI / 180) * (a - b)
      const RADIUS_OF_EARTH_IN_KM = 6371

      const dLat = distance(lat2, lat1)
      const dLon = distance(lon2, lon1)

      lat1 = toRadian(lat1)
      lat2 = toRadian(lat2)

      // Haversine Formula
      const a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
      const c = 2 * Math.asin(Math.sqrt(a))

      let finalDistance = RADIUS_OF_EARTH_IN_KM * c

      if (isMiles) {
        finalDistance /= 1.60934
      }

      return finalDistance
    }

    let j = 0
    while (j < ships.length) {
      const dealer = ships[j]
      dealer.visible = false
      dealer.distance = haversineDistance(
        [dealer.location.lat, dealer.location.lon],
        [searchCenter.lat, searchCenter.lng]
      )
      j++
    }

    const sorted = ships.sort((a, b) => a.distance - b.distance)

    const trimmed = ships.filter(s => s.distance <= searchDistance)

    j = 0
    while (j < trimmed.length) {
      const dealer = trimmed[j]
      dealer.visible = true
      dealer.label = `${j + 1}`
      j++
    }
    setResults(trimmed)
    setTempCenter(searchCenter)
    setHasSearched(true)
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = ({ value }) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const ret =
      inputLength === 0
        ? []
        : dealerships.filter(
            d => d.name.toLowerCase().slice(0, inputLength) === inputValue
          )

    return ret
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => {
    // setTempCenter(suggestion.location);
    // setResults([suggestion])

    return suggestion.name
  }

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => {
    return <span>{suggestion.name}</span>
  }

  const onChangeItem = (event, { newValue, method }) => {
    setValue(newValue)
  }

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    suggestion.label = '1'
    suggestion.visible = true
    setResults([suggestion])
    setTempCenter({
      lat: suggestion.location.lat,
      lng: suggestion.location.lon,
    })
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions({ value }))
  }

  const inputProps = {
    placeholder: 'Type name',
    value,
    onChange: onChangeItem,
  }

  return (
    <>
      <Container>
        {dealerships && (
          <div
            id="map"
            style={{ height: 'auto', width: '100%' }}
            className="mt-5 mb-2"
          >
            <LoadScript
              libraries={['places']}
              id="script-loader"
              googleMapsApiKey="AIzaSyA1yf9mWk13ntuoG5Lia0P-i3UH_igYi-A"
            >
              <div className="ad-search">
                <h4 style={{ marginBottom: '20px' }}>
                  Find accredited vehicle dealers near you
                </h4>
                <div class="ad-search-inner">
                  <div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '20px',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <h7 style={{ marginRight: '10px', minWidth: '65px' }}>
                            Vehicle Type
                          </h7>

                          <select
                            onChange={e => setSearchType(e.target.value)}
                            name="vtype"
                            id="vtype"
                            class="form-control"
                            style={{ width: '200px', borderColor: 'black' }}
                          >
                            <option value="HGV">
                              Heavy Goods Vehicle (HGV)
                            </option>
                            <option value="MC">Motorhome (HGV and LGV)</option>
                          </select>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <h7 style={{ marginRight: '10px', minWidth: '65px' }}>
                            Location
                          </h7>
                          <Autocomplete
                            onPlaceChanged={() => locationChange()}
                            onLoad={ac => setAutocomplete(ac)}
                          >
                            <input
                              type="text"
                              placeholder="Enter postcode or town"
                              className="form-control"
                              style={{ width: '200px', borderColor: 'black' }}
                            />
                          </Autocomplete>{' '}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <h7 style={{ marginRight: '10px', minWidth: '65px' }}>
                            Distance
                          </h7>
                          <select
                            onChange={e =>
                              setSearchDistance(parseInt(e.target.value))
                            }
                            name="distance"
                            id="distance"
                            class="form-control"
                            style={{ width: '200px', borderColor: 'black' }}
                          >
                            <option value="1000">National</option>
                            <option value="5">Within 5 miles</option>
                            <option value="10">Within 10 miles</option>
                            <option value="15">Within 15 miles</option>
                            <option value="20">Within 20 miles</option>
                            <option value="50">Within 50 miles</option>
                            <option value="100">Within 100 miles</option>
                            <option value="200">Within 200 miles</option>
                          </select>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <button
                            style={{
                              marginTop: '20px',
                              marginBottom: '20px',
                              backgroundColor: '#6393AA',
                              borderRadius: '10px',
                              padding: '12px 40px',
                              border: 'none',
                              fontSize: '15px',
                              fontWeight: 'bold',
                              color: 'white',
                            }}
                            onClick={() => search()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          width: '100%',
                          backgroundColor: '#000000',
                          height: '1px',
                          marginTop: '40px',
                          marginBottom: '40px',
                        }}
                      ></div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          width: '100%',
                          height: '81px',
                          top: '-39px',
                          left: 0,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: '#F0F4F7',
                            color: '#000000',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                          }}
                        >
                          OR
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <h7 style={{ marginRight: '10px', minWidth: '65px' }}>
                          Search by dealership name
                        </h7>
                        {dealerships.length > 0 && (
                          <div style={{ position: 'relative' }}>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={() =>
                                setSuggestions([])
                              }
                              getSuggestionValue={getSuggestionValue}
                              onSuggestionSelected={onSuggestionSelected}
                              renderSuggestion={renderSuggestion}
                              inputProps={inputProps}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {results.length === 0 && hasSearched && (
                <div>
                  <h5>Your results</h5>
                  <br />
                  <p>
                    No results match your search. Please try changing the search
                    options and trying again.
                  </p>
                </div>
              )}
              {results.length > 0 && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: '20px',
                      justifyContent: 'space-between',
                      marginBottom: '40px',
                    }}
                  >
                    <h5 style={{ margin: 0, padding: 0 }}>Your results</h5>
                    <div>
                      <b>View</b>
                      <button
                        style={{
                          marginLeft: '15px',
                          backgroundColor:
                            view === 'MAP' ? '#6393AA' : '#FFFFFF',
                          borderRadius: '10px',
                          padding: '12px 40px',
                          border:
                            view === 'MAP'
                              ? '1px solid #6393AA'
                              : '1px solid #000000',
                          fontSize: '15px',
                          fontWeight: 'bold',
                          color: view === 'MAP' ? '#FFFFFF' : '#000000',
                        }}
                        onClick={() => setView('MAP')}
                      >
                        Map view
                      </button>
                      <button
                        style={{
                          marginLeft: '15px',
                          backgroundColor:
                            view === 'LIST' ? '#6393AA' : '#FFFFFF',
                          borderRadius: '10px',
                          padding: '12px 40px',
                          border:
                            view === 'LIST'
                              ? '1px solid #6393AA'
                              : '1px solid #000000',
                          fontSize: '15px',
                          fontWeight: 'bold',
                          color: view === 'LIST' ? '#FFFFFF' : '#000000',
                        }}
                        onClick={() => setView('LIST')}
                      >
                        List view
                      </button>
                    </div>
                  </div>

                  {view === 'MAP' && (
                    <GoogleMap
                      className="map"
                      id="dealershipsMap"
                      mapContainerStyle={{
                        height: '500px',
                        width: '100%',
                      }}
                      zoom={zoom}
                      options={{
                        maxZoom: 15,
                      }}
                      center={mapCenter}
                      onLoad={map => {
                        map.addListener('zoom_changed', () => {
                          setZoom(map.zoom)
                        })
                        //   map.addListener('center_changed', () => {
                        //     console.log('center changed')
                        //     setTempCenter({
                        //       lat: map.center.lat(),
                        //       lng: map.center.lng(),
                        //     })
                        //   })
                      }}
                    >
                      <Marker
                        position={mapCenter}
                        title={`Chosen location`}
                        opacity={1}
                      />
                      {results.map((d, i) => {
                        let zIndex = results.length - i

                        return (
                          <Marker
                            key={d.id}
                            data={d}
                            position={{
                              lat: d.location.lat,
                              lng: d.location.lon,
                            }}
                            visible={d.visible}
                            title={`${d.name}`}
                            clickable
                            ref={markerRefs[i]}
                            opacity={1}
                            icon={{
                              // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
                              path:
                                'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
                              fillOpacity: 1,
                              fillColor: '#34B2B3',
                              strokeWeight: 1,
                              anchor: { x: 12, y: 17 },
                              strokeColor: 'white',
                              scale: 2,
                              labelOrigin: { x: 12, y: 10 },
                            }}
                            label={{
                              text: d.label,
                              color: 'white',
                              fontSize: '12px',
                            }}
                            zIndex={zIndex}
                            onClick={() => {
                              setActiveResult(d.id)
                              const section = document.querySelector(
                                '#result' + d.id
                              )
                              section.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                              })
                            }}
                          />
                        )
                      })}
                    </GoogleMap>
                  )}
                  {(view === 'LIST' || view === 'MAP') && (
                    <div
                      style={{
                        marginTop: '40px',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      {results.map(r => (
                        <div
                          id={`result${r.id}`}
                          key={r.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '40px',
                            padding: '10px',
                            backgroundColor:
                              activeResult === r.id ? '#F0F4F7' : '',
                          }}
                          className="dcMapResult"
                        >
                          <div style={{ width: '60px' }}>
                            <div
                              style={{ position: 'relative', width: '57px' }}
                            >
                              <svg
                                viewBox="0 0 20 25"
                                height="60"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill="#34B2B3"
                                  d="M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z"
                                />
                              </svg>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  height: '100%',
                                  width: '100%',
                                }}
                              >
                                <p
                                  style={{
                                    color: 'white',
                                    paddingBottom: '8px',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    fontSize: '14px',
                                  }}
                                >
                                  {r.label}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h3 style={{ marginBottom: '5px' }}>{r.name}</h3>
                            <div style={{ marginBottom: '5px' }}>
                              <FontAwesomeIcon
                                style={{
                                  color: '#34B2B3',
                                  width: '20px',
                                  marginRight: '15px',
                                }}
                                icon={faMap}
                              />
                              <a
                                target="_blank"
                                href={`https://www.google.com/maps/search/?api=1&query=${r.name} ,${r.address}, ${r.postcode}`}
                                style={{ marginBottom: '5px' }}
                              >
                                {r.address}, {r.postcode}
                              </a>
                            </div>

                            {r.phoneNumber && (
                              <div style={{ marginBottom: '5px' }}>
                                <FontAwesomeIcon
                                  style={{
                                    color: '#34B2B3',
                                    width: '20px',
                                    marginRight: '15px',
                                  }}
                                  icon={faPhone}
                                />
                                <a href={`tel:${r.phoneNumber}`}>
                                  {r.phoneNumber}
                                </a>
                              </div>
                            )}
                            {r.emailSales && (
                              <div style={{ marginBottom: '5px' }}>
                                <FontAwesomeIcon
                                  style={{
                                    color: '#34B2B3',
                                    width: '20px',
                                    marginRight: '15px',
                                  }}
                                  icon={faEnvelope}
                                />
                                <a href={`mailto:${r.emailSales}`}>
                                  {r.emailSales}
                                </a>
                              </div>
                            )}
                            {r.website && (
                              <div style={{ marginBottom: '5px' }}>
                                <FontAwesomeIcon
                                  style={{
                                    color: '#34B2B3',
                                    width: '20px',
                                    marginRight: '15px',
                                  }}
                                  icon={faMousePointer}
                                />
                                <a target="_blank" href={r.website}>
                                  Visit the website
                                </a>
                              </div>
                            )}
                            {r.distance && (
                              <p
                                style={{
                                  marginBottom: '5px',
                                  color: '#AFAFAF',
                                }}
                              >
                                {r.distance.toFixed(1)} miles
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </LoadScript>
          </div>
        )}
      </Container>
    </>
  )
}

export default DealershipsComponent
