import React from 'react'
import { navigate } from '@reach/router'
import { Container, FormGroup, Input } from 'reactstrap'

class BlockJumpLinks extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const d = this.props.data
    const links = d.links.filter(p => p.fields)

    if (!d.showHasBoxes) {
      return (
        <div className="full-width-block">
          <Container>
            <div className="jump-section">
              <div className="d-none d-lg-block">
                <ul>
                  <li>Click to jump to section:</li>
                  {links.map((l, i) => {
                    let linkClass = ''
                    let padding = '25px 0px'
                    if (l.fields.showAsBlueButton) {
                      linkClass = 'btn small btn-content btn-cons--darkblue'
                      padding = '12px 0px'
                    }
                    if (l.fields.showAsGreenButton) {
                      linkClass = 'btn small btn-content btn-cons--green'
                      padding = '12px 0px'
                    }
                    return (
                      <li style={{ padding: padding }} key={`l${i}`}>
                        <a href={`#${l.fields.jumpLinkId}`}>
                          {l.fields.title.split('\n').map((item, key) => {
                            return (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            )
                          })}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="d-lg-none">
                <FormGroup>
                  <Input
                    aria-label="Jump to section"
                    type="select"
                    name="select"
                    defaultValue={'title'}
                    onChange={v => {
                      navigate(v.target.value)
                    }}
                  >
                    <option value="title" disabled>
                      Jump to section...
                    </option>
                    {links.map((l, i) => (
                      <option key={`o${i}`} value={`#${l.fields.jumpLinkId}`}>
                        {l.fields.title.replace('\n', ' ')}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
            </div>
          </Container>
        </div>
      )
    }
    return (
      <div className="full-width-block">
        <Container>
          <div className="vehicle-anchor-boxes">
            {links.map((l, i) => (
              <a
                className="vehicle-anchor-box"
                href={`#${l.fields.jumpLinkId}`}
              >
                {l.fields.title}
                {l.fields.title.includes('Sign up') && (
                  <div className="vab-little">Sign up</div>
                )}
                {!l.fields.title.includes('Sign up') && (
                  <div className="vab-little">Find out more</div>
                )}
              </a>
            ))}
          </div>
        </Container>
      </div>
    )
  }
}

export default BlockJumpLinks
