import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Button from './Button'

class BlockNumberAndText extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'button':
              return <Button data={node.data.target.fields} />
            default:
              break
          }
        },
        renderText: text => {
          text = text.replace(/O2/g, 'O₂')
          text = text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
          return text
        },
      },
    }

    const d = this.props.data

    return (
      <div
        id={d.jumpLinkId}
        className="third-width-block text-and-number-block px-4 px-md-5 py-2 mb-4"
      >
        <div className="round-number">{d.number}</div>

        {!!d.text && (
          <div className="rich-text mt-2">
            {documentToReactComponents(d.text, options)}
          </div>
        )}
      </div>
    )
  }
}

export default BlockNumberAndText
