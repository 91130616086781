import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Button from './Button'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Col, Container, Row } from 'reactstrap'

class BlockTecDocs extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const d = this.props.data

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'button':
              return <Button data={node.data.target.fields} />

            default:
              break
          }
        },
        [INLINES.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'warningIcon':
              if (
                ![
                  'Black line',
                  'Blue line',
                  'Red line',
                  'Amber line',
                  'Green line',
                  'Purple line',
                ].includes(node.data.target.fields.type)
              ) {
                return (
                  <FontAwesomeIcon
                    className="mr-3"
                    style={{
                      position: 'absolute',
                      left: '-40px',
                      fontSize: '20px',
                    }}
                    icon={faExclamationTriangle}
                  />
                )
              } else {
                let colorLine = '#000000'
                switch (node.data.target.fields.type) {
                  case 'Black line':
                    colorLine = '#000000'
                    break
                  case 'Blue line':
                    colorLine = '#4173AB'
                    break
                  case 'Red line':
                    colorLine = '#D41723'
                    break
                  case 'Amber line':
                    colorLine = '#FF7F00'
                    break
                  case 'Green line':
                    colorLine = '#8CC47D'
                    break
                  case 'Purple line':
                    colorLine = '#C388C0'
                    break

                  default:
                    break
                }

                return (
                  <div
                    style={{
                      position: 'absolute',
                      display: 'block',
                      left: '-10px',
                      marginTop: '7px',
                      width: '40px',
                      height: '10px',
                      backgroundColor: colorLine,
                    }}
                  ></div>
                )
              }

            default:
              break
          }
        },
      },
      renderText: text => {
        text = text.replace(/O2/g, 'O₂')
        text = text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
        return text
      },
    }

    let alignClass = 'text-left'

    if (d.align === 'Center') {
      alignClass = 'text-center'
    }

    if (d.align === 'Right') {
      alignClass = 'text-right'
    }

    let widthClass = `half-width-block  ${
      d.reducedWidth ? 'half-width-block--reduced' : ''
    }`

    if (d.fullWidth) {
      widthClass = 'full-width-block container'
    }

    return (
      <>
        <Container className="mt-5" id={d.id}>
          <Row>
            <Col md={{ size: 12 }} lg={{ size: 6 }} className="vc">
              <h2 className="black">{d.title}</h2>
              <div>{documentToReactComponents(d.description, options)}</div>
            </Col>
          </Row>
        </Container>

        <Container className="mb-5">
          <Row>
            <Col>
              {(!d.columns || d.columns === 3) && (
                <div class={`document-header d-none d-lg-flex`}>
                  <div className={`document-header__num`}>
                    {!d.column1Name || d.column1Name === 'Num'
                      ? 'No.'
                      : d.column1Name}
                  </div>
                  <div className="document-header__name">
                    {d.column2Name || 'Name'}
                  </div>
                  <div className="document-header__holder">
                    {d.column3Name || 'Description'}
                  </div>
                </div>
              )}

              {d.columns === 4 && (
                <div class="document-header d-none d-lg-flex">
                  <div className={`document-header__num `}>
                    {!d.column1Name || d.column1Name === 'Num'
                      ? 'No.'
                      : d.column1Name}
                  </div>
                  <div className="document-header__name">
                    {d.column2Name || 'Name'}
                  </div>
                  <div className="document-header__name">
                    {d.column4Name || 'Num'}
                  </div>
                  <div className="document-header__holder">
                    {d.column3Name || 'Description'}
                  </div>
                </div>
              )}

              {d.files.map((f, i) => {
                if (!f.fields) {
                  return <></>
                }

                if (!d.columns || d.columns === 3) {
                  return (
                    <div
                      key={`${d.id}${i}`}
                      className={`document ${
                        i % 2 === 1 ? 'document--odd' : 'document--even'
                      }`}
                    >
                      <div
                        className={`document__num ${
                          f.fields.number === '-' ? 'd-none' : ''
                        }`}
                      >
                        {f.fields.number}
                      </div>
                      <div className="document__name">{f.fields.title}</div>

                      <div className="document__holder">
                        <div className="document__description">
                          {documentToReactComponents(
                            f.fields.description,
                            options
                          )}
                        </div>
                        {f.fields.file && (
                          <a
                            className="btn-download"
                            target="_blank"
                            href={f.fields.file.fields.file.url}
                          >
                            Download
                          </a>
                        )}
                      </div>
                    </div>
                  )
                }
                if (d.columns === 4) {
                  return (
                    <div
                      key={`${d.id}${i}`}
                      className={`document ${
                        i % 2 === 1 ? 'document--odd' : 'document--even'
                      }`}
                    >
                      <div
                        className={`document__num ${
                          f.fields.number === '-' ? 'd-none' : ''
                        }`}
                      >
                        {f.fields.number}
                      </div>
                      <div className="document__name">{f.fields.title}</div>
                      <div className="document__name">{f.fields.misc}</div>

                      <div className="document__holder">
                        <div className="document__description">
                          {documentToReactComponents(
                            f.fields.description,
                            options
                          )}
                        </div>
                        {f.fields.file && (
                          <a
                            className="btn-download"
                            target="_blank"
                            href={f.fields.file.fields.file.url}
                          >
                            Download
                          </a>
                        )}
                      </div>
                    </div>
                  )
                }
              })}
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default BlockTecDocs
