import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ReactPlayer from 'react-player'

class BlockVideo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const d = this.props.data

    return (
      <div id={d.jumpLinkId} className="half-width-block p-4 p-md-5 mb-4">
        <div className="react-player-wrapper">
          <ReactPlayer
            width="100%"
            className="react-player"
            height="100%"
            controls
            url={d.videoUrl}
          />
        </div>
        {!!d.text && (
          <div className="rich-text mt-2">
            {documentToReactComponents(d.text)}
          </div>
        )}
      </div>
    )
  }
}

export default BlockVideo
