import React from 'react'
import Container from 'reactstrap/lib/Container'

class BlockTitle extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const d = this.props.data

    let marginBottom = ''
    let marginTop = ''

    if (d.removeBottomPadding) {
      marginBottom = 0
    }
    if (d.removeTopPadding) {
      marginTop = 0
    }

    return (
      <div id={d.jumpLinkId} className="full-width-block">
        <Container>
          <div
            className="text-center pb-0 block-title"
            style={{ marginBottom, marginTop }}
          >
            <h1>{d.title}</h1>
          </div>
        </Container>
      </div>
    )
  }
}

export default BlockTitle
