import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import InputGroup from 'reactstrap/lib/InputGroup'
import Input from 'reactstrap/lib/Input'
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon'
import Button from 'reactstrap/lib/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faExclamationCircle,
  faLeaf,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import WizardSignUp from './wizard-sign-up'
import NewsLetter from './newsletter'
class BlockRegisterInterest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      postcode: null,
      error: null,
      success: null,
      showModal: false,
      readMore: false,
    }
    this.signup = this.signup.bind(this)
  }

  signup() {
    this.setState({ error: '', success: '' })

    fetch(
      `https://cleanairgm.com/.netlify/functions/postMoosend?email=${this.state.email}&postcode=${this.state.postcode}&id=6b98fdf0-2574-4905-8e7a-3af160342064`
    )
      .then(res => {
        res.json().then(d => {
          // add timeout to give illusion of waiting at least a second for
          // response

          setTimeout(() => {
            if (d.success) {
              this.setState({ success: 'Thank you for subscribing.' })
            }

            if (!d.success) {
              this.setState({ error: d.message })
            }
          }, 1)
        })
      })
      .catch(e => {
        console.log(error)
        this.setState({ error: 'Server error.' })

        return
      })
  }

  render() {
    const d = this.props.data

    let flowType = null

    if (d.formType && d.formType === 'Home page newsletter') {
      return (
        <div style={{ width: '100%' }} id="newsletter" className="mb-0 mt-0">
          <NewsLetter blue />
        </div>
      )
    }

    if (d.formType && d.formType !== 'Apply for accreditation') {
      if (d.formType === 'Signup Bus') {
        flowType = 'bus'
      }
      if (d.formType === 'Signup LGV') {
        flowType = 'lgv'
      }
      if (d.formType === 'Signup Caravan') {
        flowType = 'caravan'
      }
      if (d.formType === 'Signup Taxi') {
        flowType = 'taxi'
      }
      if (d.formType === 'Signup HGV') {
        flowType = 'hgv'
      }
      if (d.formType === 'Signup Generic') {
        flowType = 'generic'
      }
    }

    if (!flowType && d.formType !== 'Apply for funding') {
      return (
        <>
          {this.state.showModal && (
            <div className="register-modal">
              <div className="register-modal-inner">
                <button
                  className="close-button"
                  onClick={() => {
                    this.setState({ showModal: false })
                  }}
                >
                  <FontAwesomeIcon
                    className="close-icon"
                    icon={faTimesCircle}
                  />
                </button>
                <div className="interest-box-vanilla">
                  <h2>{d.title}</h2>
                  <p>
                    Please make sure you've read all the information on the
                    Financial Support Scheme and the dealership accredidation
                    process on this page before you apply.
                  </p>

                  <a
                    className="btn btn-applynowrev mt-4 mb-4"
                    target="_blank"
                    href="https://dealerships.cleanairgm.com"
                  >
                    Apply now
                  </a>

                  <div
                    onClick={() => {
                      this.setState({ showModal: false })
                    }}
                    className={`rich-text ${
                      !d.applyNowMode
                        ? ''
                        : 'w-100 rich-text--large d-flex justify-content-between flex-wrap'
                    }`}
                  >
                    {documentToReactComponents(d.text)}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            id={d.jumpLinkId}
            className="half-width-block p-4 p-md-5 mb-4 text-center"
          >
            <div
              className={`interest-box ${
                d.applyNowMode ? 'interest-box--applynow' : ''
              }`}
            >
              <h2>{d.title}</h2>
              <p>{d.introText}</p>
              {!d.applyNowMode && (
                <>
                  <InputGroup>
                    <Input
                      type="text"
                      autoComplete="postcode"
                      aria-label="Enter dealership postcode"
                      placeholder="Enter dealership postcode"
                      onChange={t => {
                        this.setState({ postcode: event.target.value })
                      }}
                    />
                  </InputGroup>
                  <div style={{ height: '20px' }} />
                  <InputGroup>
                    <Input
                      type="email"
                      autoComplete="email"
                      aria-describedBy="err"
                      aria-label="Enter your email address"
                      placeholder="Enter your email address"
                      onChange={t => {
                        this.setState({ email: event.target.value })
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={() => {
                          this.signup()
                        }}
                        color="interest"
                      >
                        Submit
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <div style={{ height: '20px' }} />
                  {(!!this.state.error || !!this.state.success) && (
                    <p
                      id="err"
                      className={`fade alert ${
                        !!this.state.error ? 'alert-danger' : 'alert-success'
                      } show`}
                    >
                      {!!this.state.error && (
                        <FontAwesomeIcon
                          className="mr-3"
                          icon={faExclamationCircle}
                        />
                      )}
                      {!!this.state.success && (
                        <FontAwesomeIcon className="mr-3" icon={faCheck} />
                      )}
                      {this.state.error}
                      {this.state.success}
                    </p>
                  )}
                </>
              )}
              {d.applyNowMode && (
                <div className="mt-4 mb-4">
                  <Button
                    onClick={() => this.setState({ showModal: true })}
                    color="applynow"
                  >
                    Apply Now
                  </Button>
                </div>
              )}
              <div
                className={`rich-text ${
                  !d.applyNowMode
                    ? ''
                    : 'w-100 rich-text--large d-flex justify-content-between flex-wrap'
                }`}
              >
                {documentToReactComponents(d.text)}
              </div>
            </div>
          </div>
        </>
      )
    }

    if (!flowType && d.formType === 'Apply for funding') {
      return (
        <>
          {this.state.showModal && (
            <div className="register-modal">
              <div className="register-modal-inner">
                <button
                  className="close-button"
                  onClick={() => {
                    this.setState({ showModal: false })
                  }}
                >
                  <FontAwesomeIcon
                    className="close-icon"
                    icon={faTimesCircle}
                  />
                </button>
                <div className="interest-box-vanilla">
                  <h2>{d.title}</h2>
                  <p>
                    Please make sure you have read all the information on this
                    page, including the terms and conditions and key facts
                    below, before starting your application.
                  </p>

                  <a
                    className="btn btn-applynowrev mt-4 mb-4"
                    target="_blank"
                    href="https://financialsupport.cleanairgm.com/"
                  >
                    Apply now
                  </a>

                  <div
                    onClick={() => {
                      this.setState({ showModal: false })
                    }}
                    className={`rich-text ${
                      !d.applyNowMode
                        ? ''
                        : 'w-100 rich-text--large d-flex justify-content-between flex-wrap'
                    }`}
                  >
                    {documentToReactComponents(d.text)}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            id={d.jumpLinkId}
            className="half-width-block p-4 p-md-5 mb-4 text-center"
          >
            <div
              className={`interest-box ${
                d.applyNowMode ? 'interest-box--applynow' : ''
              }`}
            >
              <h2>{d.title}</h2>
              {d.introText && <p>{d.introText}</p>}
              {d.introContent && (
                <div
                  className={`h3-centered rich-text w-100 rich-text--large d-flex justify-content-between flex-wrap`}
                >
                  {documentToReactComponents(d.introContent)}
                </div>
              )}
              {!d.applyNowMode && (
                <>
                  <InputGroup>
                    <Input
                      type="text"
                      autoComplete="postcode"
                      aria-label="Enter dealership postcode"
                      placeholder="Enter dealership postcode"
                      onChange={t => {
                        this.setState({ postcode: event.target.value })
                      }}
                    />
                  </InputGroup>
                  <div style={{ height: '20px' }} />
                  <InputGroup>
                    <Input
                      type="email"
                      autoComplete="email"
                      aria-label="Enter your email address"
                      placeholder="Enter your email address"
                      onChange={t => {
                        this.setState({ email: event.target.value })
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={() => {
                          this.signup()
                        }}
                        color="interest"
                      >
                        Submit
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <div style={{ height: '20px' }} />
                  {(!!this.state.error || !!this.state.success) && (
                    <p
                      className={`fade alert ${
                        !!this.state.error ? 'alert-danger' : 'alert-success'
                      } show`}
                    >
                      {!!this.state.error && (
                        <FontAwesomeIcon
                          className="mr-3"
                          icon={faExclamationCircle}
                        />
                      )}
                      {!!this.state.success && (
                        <FontAwesomeIcon className="mr-3" icon={faCheck} />
                      )}
                      {this.state.error}
                      {this.state.success}
                    </p>
                  )}
                </>
              )}
              {d.applyNowMode && (
                <div className="mt-4 mb-4">
                  <a
                    className="btn btn-applynow mt-4 mb-4"
                    target="_blank"
                    href="https://financialsupport.cleanairgm.com/"
                  >
                    Apply now
                  </a>
                </div>
              )}
              <div
                className={`rich-text ${
                  !d.applyNowMode
                    ? ''
                    : 'w-100 rich-text--large d-flex justify-content-between flex-wrap'
                }`}
              >
                {documentToReactComponents(d.text)}
              </div>
            </div>
          </div>
        </>
      )
    }

    return (
      <div
        id={d.jumpLinkId}
        className="half-width-block p-4 p-md-5 mb-4 text-center"
      >
        <div
          className={`interest-box ${
            d.applyNowMode ? 'interest-box--applynow' : ''
          }`}
        >
          <img
            alt=""
            src="/images/envelope.png"
            style={{ width: '50px', marginBottom: '20px' }}
          />
          <h2>{d.title}</h2>
          <div className="text-left">{documentToReactComponents(d.text)}</div>
          <WizardSignUp type={flowType} />
        </div>
      </div>
    )
  }
}

export default BlockRegisterInterest
