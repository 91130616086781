import React from 'react'
import Container from 'reactstrap/lib/Container'

class BlockRowBreak extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const d = this.props.data

    return <div className="full-width-block" style={{ height: d.height }}></div>
  }
}

export default BlockRowBreak
